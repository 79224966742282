<template>
  <div>
    <form v-if="!isResetting" class="modal-inner-container" @submit.prevent="login">
      <div class="w-full">
        <div class="text-n-4xl font-bold">
          {{$t('security.twofa.twofa_verification')}}
        </div>
        
        <div class="text-n-md font-medium mt-n-5xl">
          {{$t('security.twofa.your_account_requires')}}
        </div>
        
        <div class="text-n-md font-medium">
          {{$t('security.twofa.enter_code')}}
        </div>
        
        <div @click="toggleReset" class="text-n-md font-bold mt-n-4xl cursor-pointer">
          {{$t('security.twofa.lost_access')}}
        </div>

        <input ref="twofaRef" v-model="twofa" data-testid="input-twofa" type="text" class="v3 mt-n-4xl" data-lpignore="true" />
      </div>

      <ButtonV2
        :inactive="isLoading || !twofa"
        :label="$t('security.twofa.submit')"
        testId="btn-submit"
        class="mt-n-5xl"
        submit
        wide
      />
    </form>
    <form v-else class="modal-inner-container" @submit.prevent="recoverTwoFactor">
      <div class="w-full">
        <div class="text-n-4xl font-bold">
          {{$t('security.twofa.reset_twofa')}}
        </div>
        
        <div class="text-n-md font-medium mt-n-5xl">
          {{$t('security.twofa.if_you_lost_access')}}
        </div>
        
        <div class="text-n-md font-medium mt-n-3xl">
          {{$t('security.twofa.if_you_dont_have')}}
          <span @click="goToPage('/support')" to="/support" class="font-bold cursor-pointer">{{$t('navigation.customer_support')}}</span>.
        </div>

        <input v-model="recoveryCode" type="text" data-testid="input-recoveryCode" class="v3 mt-n-4xl" data-lpignore="true" />
      </div>

      <div class="flex w-full mt-n-5xl">
        <ButtonV2
          @onClick="toggleReset"
          :inactive="isLoading"
          :label="$t('navigation.back')"
          testId="btn-back"
          version="secondary"
          wide
        />
        <ButtonV2
          :inactive="isLoading || !recoveryCode"
          :label="$t('continue')"
          testId="btn-continue"
          class="ml-n-xl"
          submit
          wide
        />
      </div>
    </form>

    <HCaptcha
      ref="hCaptchaRef"
      @onChallengeExpired="onClose"
      @onClosed="onClose"
      @onError="onError"
      @onExpired="onClose"
      @onVerify="onVerify"
    />
  </div>
</template>

<script>
import TWO_FACTOR_AUTH_RESET_STEP_ONE from '@/graphql/mutations/TwoFactorAuthResetStepOne.gql';
import TWO_FACTOR_AUTH_RESET_STEP_ONE_SSO from '@/graphql/mutations/TwoFactorAuthResetStepOneSso.gql';
import USER_LOGIN from '@/graphql/mutations/UserLogin.gql';
import ButtonV2 from '@/stories/misc/ButtonV2';
import HCaptcha from '@/components/misc/HCaptcha';

import { isApiError } from '@/utils/error-handler';

export default {
  name: 'TwoFactorAuthenticationVerification',

  components: {
    ButtonV2,
    HCaptcha,
  },

  data() {
    return {
      captcha: '',
      isLoading: false,
      isResetting: false,
      recoveryCode: '',
      twofa: '',
    };
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'SIGN_IN_TWO_FACTOR_VIEWED',
    });
    this.$nextTick(() => {
      this.$refs.twofaRef.focus();
    });
  },

  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      default: null,
    },
    keepSignedIn: {
      type: Boolean,
      default: true,
    },
    authData: {
      type: Object,
      default: null,
    }
  },

  methods: {
    goToPage(page) {
      this.$router.push(page).catch(()=>{});
      this.hideModal();
    },

    async login() {
      if (this.authData.ssoAuthToken) {
        await this.loginWithSso(this.authData.ssoOrigin);
      } else {
        await this.loginWithPassword();
      }
    },

    async loginWithPassword() {
      try {
        this.isLoading = true;
        const { email, password, keepSignedIn } = this.authData;
        const response = await this.apolloApiCall({
          mutation: USER_LOGIN,
          variables: {
            email,
            password,
            keep_me: keepSignedIn,
            captcha: this.captcha,
            twofa: this.twofa,
          }
        });
        const data = response.data['_login'];
        await this.handleLogin(data, true);
        this.hideModal();
      } catch (err) {
        this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: false });
        if (isApiError(err)) {
          if (err['error_code'] === 'invalid_captcha') {
            return this.onSubmit();
          }
        }

        this.isLoading = false;

        await this.showError(err);
      }
    },

    async loginWithSso(origin) {
      this.isLoading = true;
      try {
        const authResult = await this.ssoAuth(this.authData.ssoAuthToken, origin, this.twofa);
        await this.handleLogin(authResult);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    onClose() {
      this.isLoading = false;
    },

    onError() {
      this.isLoading = false;
    },

    onSubmit() {
      this.$refs.hCaptchaRef.execute();
    },

    async onVerify(captcha) {
      this.captcha = captcha;
      this.isResetting ? await this.recoverTwoFactor() : await this.login();
    },

    async recoverTwoFactor() {
      if (this.authData.ssoOrigin) {
        await this.recoverTwoFactorWithSso(this.authData.ssoOrigin);
      } else {
        await this.recoverTwoFactorWithPassword();
      }
    },

    async recoverTwoFactorWithPassword() {
      this.isLoading = true;
      try {
        const { email, password, } = this.$props;

        await this.apolloApiCall({
          mutation: TWO_FACTOR_AUTH_RESET_STEP_ONE,
          variables: {
            email,
            password,
            twofa_recover: this.recoveryCode,
            captcha: this.captcha,
          }
        });

        this.hideModal();
        this.$store.commit('ui/showPrompt', {
          type: 'success',
          title: this.$t('security.twofa.reset_twofa'),
          message: this.$t('security.twofa.we_have_sent_you')
        });
      } catch (err) {
        if (isApiError(err)) {
          if (err['error_code'] === 'invalid_captcha') {
            return this.onSubmit();
          }
        }

        this.isLoading = false;
        await this.showError(err);
      }
    },

    async recoverTwoFactorWithSso(origin) {
      this.isLoading = true;
      try {
        await this.apolloApiCall({
          mutation: TWO_FACTOR_AUTH_RESET_STEP_ONE_SSO,
          variables: {
            type: origin,
            auth_token: this.authData.ssoAuthToken,
            twofa_recover: this.recoveryCode,
          }
        });
        this.hideModal();
        this.$store.commit('ui/showPrompt', {
          type: 'success',
          title: this.$t('security.twofa.reset_twofa'),
          message: this.$t('security.twofa.we_have_sent_you')
        });
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    toggleReset() {
      this.isResetting = !this.isResetting;
    }
  },
};
</script>

<style scoped>
</style>
